<template>
	<div class="newsCont">
		<banner :picS="bannerS"></banner>
		<div class="widthBox">
			<div class="newsTitle">
				<titleOne id="news_1" en='news information' title="最新ニュース"></titleOne>
			</div>
			<ul class="newsTab acea-row row-around-wrapper">
				<li @click="newsTabIndex(index)" v-for="(item,index) in newsTabS" :class="newsIndex==index?'active':''">{{item.name}}</li>
			</ul>
			<ul class="newsList">
				<li v-for="(item,index) in newsData">
					<router-link :to="'/news_detail?id='+item.id">
						<img :src="item.img" alt="" class="pic">
						<div class="cont">
							<div class="h4Name">
								<h4>
									{{item.title}}
								</h4>
								<span class="time hidden-xs-only">{{item.create_time}}</span>
							</div>
							
							<p>{{item.describe}}</p>
							<div class="footer">
								<span class="time hidden-sm-and-up">{{item.create_time}}</span>
								<span class="more">
									クリックしてもっと見る
									<img class="icon hidden-xs-only" src="@/assets/icon_08.png" alt="">
								</span>
							</div>
						</div>
					</router-link>
				</li>
			</ul>
		</div>
		<el-pagination v-if="total>10" :page-size="pageSize" :current-page="page" @current-change="joinChange" layout="prev, pager, next" :total="total"></el-pagination>
	</div>
</template>

<script>
	import titleOne from '@/components/titleOne.vue'
	import banner from '@/components/banner.vue'
	export default {
	  name: 'news',
	  components: {
		  banner,titleOne
	  },
	  computed: {
	  	bannerS() {
	  		return this.$store.state.bannerS
	  	},
	  },
	  data:function() {
	  	return {
			newsIndex:0,
			newsTabS:[],
			page:1,
			pageSize:10,
			total:1,
			newsData:[]
	  	};
	  },
	  created() {
	  	this.$api.get('home/index/newsCategory',{
	  	}).then(res=>{
	  		this.newsTabS = res.data
			this.getList()
	  	})
	  },
	  mounted() {
	  	this.$nextTick(function(){
	  		if(this.$route.query.to != undefined || this.$route.query.to != null){
	  			setTimeout(()=>{
	  				document.getElementById(this.$route.query.to).scrollIntoView()
	  			},500)
	  		}else{
	  			document.body.scrollIntoView()
	  		}
	  	})
	  },
	  methods:{
		  getList(){
			  var {newsTabS,newsIndex,page,pageSize} = this
			  this.$api.get('home/index/newsInformation',{
				  news_category_id:newsTabS[newsIndex].id,
				  page:page,
				  pageSize:pageSize,
			  }).then(res=>{
			  	this.newsData = res.data.news
				this.total = res.data.total
			  })
		  },
		  newsTabIndex(e){
			  this.newsIndex= e
			  this.page = 1
			  this.getList()
		  },
		  joinChange(e){
		  	this.page = e
		  	this.getList()
		  }
	  }
	}
</script>

<style lang="scss" scoped>
	.newsCont{
		width:100%;
		.newsTitle{
			margin-top:70px;
		}
		.el-pagination{
			margin:50px auto 0;
			text-align: center;
			button{
				line-height:40px;
				height:40px;
				padding:0 8px;
				font-weight: 500;
				background-color: rgba($color: #1D2A55, $alpha:1);
				color: #FFF;
				i{
					font-size:24px;
				}
				&:disabled {
				    color: #1D2A55;
				    background-color: rgba($color: #1D2A55, $alpha:0.1);
				    cursor: not-allowed;
				}
			}
			li{
				line-height:40px;
				height:40px;
				padding:0 8px;
				font-size:24px;
				margin:0 5px;
				font-weight: 500;
				color:rgba($color: #1D2A55, $alpha: 0.4);
				&.active{
					color:rgba($color: #1D2A55, $alpha: 1);
				}
			}
		}
		.newsTab{
			margin-top:50px;
			li{
				height:80px;
				padding: 0 20px;
				line-height:80px;
				text-align: center;
				color:#1D2A55;
				font-size:36px;
				box-sizing: border-box;
				border:1px solid #1D2A55;
				margin:0 35px;
				cursor: pointer;
				&.active{
					color:#FFF;
					background:#1D2A55;
				}
			}
		}
		.newsList{
			li{
				padding:25px;
				border:1px solid #1D2A55;
				margin-top:50px;
				a{
					display: block;
					&::after{
						content:'';display: block;width:0;height:0;overflow: hidden;clear: both;
					}
				}
				&::after{
					content:'';display: block;width:0;height:0;overflow: hidden;clear: both;
				}
				.pic{
					width:450px;
					float:left;
				}
				.cont{
					margin-left:500px;
					.h4Name{
						position: relative;
						span{
							position: absolute;
							right:0;
							top:0;
							font-size:18px;
							font-weight: 500;
						}
					}
					h4{
						color:#1D2A55;
						font-size:30px;
						margin-top:40px;
						line-height:48px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						margin-right:160px;
					}
					p{
						margin-top:20px;
						line-height:32px;
						font-size:18px;
						color:rgba($color: #1D2A55, $alpha: 0.8);
					}
					.more{
						display:inline-block;
						width:240px;
						height:36px;
						border:1px solid #1D2A55;
						color:#1D2A55;
						text-align: center;
						line-height:36px;
						cursor: pointer;
						font-size:18px;
						position: relative;
						margin-top:30px;
						.icon{
							position: absolute;
							top:50%;
							right:-54px;
							width:77px;
							transform: translate(0,-50%);
						}
					}
				}
			}
		}
	}
	
	@media screen and(max-width:1400px) {
		.newsCont{
			.newsTab{
				margin-top:40px;
				li{
					height:72px;line-height:72px;
					font-size:28px;
					margin:0 25px;
				}
			}
			.newsList{
				li{
					padding:25px;
					margin-top:40px;
					.pic{
						width:400px;
					}
					.cont{
						margin-left:450px;
						.h4Name{
							position: relative;
							span{
								font-size:16px;
								position: absolute;
								right:0;
								top:0;
							}
						}
						h4{
							color:#1D2A55;
							font-size:28px;
							margin-top:30px;
							line-height:40px;
							
							
						}
						
						p{
							margin-top:15px;
							line-height:28px;
							font-size:16px;
						}
						.more{
							width:150px;
							height:36px;
						}
					}
				}
			}
		}
	}
	@media screen and(max-width:1200px) {
		.newsCont{
			.newsTab{
				li{
					height:60px;line-height:60px;
					font-size:24px;
					margin:0 20px;
				}
			}
			.newsList{
				li{
					padding:20px;
					margin-top:30px;
					.pic{
						width:350px;
					}
					.cont{
						margin-left:400px;
						.h4Name{
							span{
								font-size:14px;
							}
						}
						h4{
							font-size:24px;
							margin-top:25px;
							line-height:36px;
						}
						p{
							margin-top:15px;
							line-height:28px;
							font-size:14px;
						}
						.more{
							width:150px;
							height:36px;
						}
					}
				}
			}
		}
	}
	@media screen and(max-width:768px) {
		.newsCont{
			.newsTitle{
				margin-top:20px;
			}
			.newsTab{
				margin-top:20px;
				li{
					margin:0;
					font-size:12px;
					height:36px;
					line-height:36px;
					margin-left:10px;
					&:first-child{
						margin-left:0;
					}
				}
			}
			.newsList{
				li{
					padding:0px;
					margin-top:20px;
					border: none;
					background: #F5F5F5;
					.pic{
						width:100%;
						float:none;
					}
					.cont{
						margin-left:0;
						padding: 20px 12px;
						h4{
							margin-right:0;
							margin-top:0px;
							font-size:16px;
							color: #272E4D;
						}
						p{
							margin-top:0;
							line-height:20px;
		color: #3e404d;
							font-size: 12px;
						}
						.footer{
							display: flex;
							justify-content: space-between;
							align-items: center;
							margin-top: 15px;
							.time{
								margin-top:0px;
								display: block;
								color: #8A8D99;
								font-size: 10px;
							}
							.more{
								border: none;
								font-size: 12px;
								color: #3E404D;
								height: auto;
								line-height: 12px;
								border-bottom: 1px solid #3E404D;;
								margin: 0px;
								width: auto;
								// display: none;
							}
						}
					}
				}
			}
		}
	}


	@media screen and(max-width:400px) {
		.newsCont {
			.newsTab{
				margin-top:20px;
				li{
					margin:0;
					font-size:12px;
					height:36px;
					line-height:36px;
					margin-left:0px !important;
					margin-top: 20px !important;
					&:first-child{
						margin-left:0;
					}
				}
			}
		}
		
	}
	
	
</style>
